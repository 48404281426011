import { format } from 'date-fns'
import React from 'react'

import {
  Candidate,
  CandidateStatus,
  SimpleAnswer,
  TableVariable,
  downloadRecruitmentFileAnswer
} from '../../../../requests'
import {
  ColumnFixedType,
  DatacColorStatusSelect,
  DatacFileDownloadPreview,
  DatacTooltip,
  scopedIntlType
} from '../../../common'

export const renderContactDetail = (firstName: string, lastName: string, email: string) => (
  <div className="candidates-table__name">
    <div className="candidates-table__name__full-name">
      {firstName} {lastName}
    </div>
    {email}
  </div>
)

const CustomCell: React.VFC<{ answer: SimpleAnswer }> = ({ answer }) => {
  if (!answer) return null

  return answer.isFile === false ? (
    <DatacTooltip title={answer.value}>
      <div className="subject-repository-dashboard__table__custom">{answer.value}</div>
    </DatacTooltip>
  ) : (
    <DatacFileDownloadPreview
      filename={answer.name}
      fileId={answer.uuid}
      downloadFile={downloadRecruitmentFileAnswer}
    />
  )
}

interface GetColumnsOptions {
  columnNames: {
    contactDetail: string
    stage: string
    applied: string
    survey: string
    center: string
  }
  variables: string[]
  allVariables: TableVariable[]
}

export const getListColumns = ({ columnNames, variables, allVariables }: GetColumnsOptions) => {
  return [
    {
      title: columnNames.contactDetail,
      dataIndex: 'email',
      key: 'email',
      ellipsis: true,
      width: variables.length > 4 && '24rem',
      sorter: true,
      fixed: 'left' as ColumnFixedType,
      render: (email: string, { firstName, lastName }: Partial<Candidate>) =>
        renderContactDetail(firstName, lastName, email)
    },
    {
      title: columnNames.stage,
      dataIndex: 'status',
      key: 'status',
      width: '23rem',
      sorter: true,
      fixed: 'left' as ColumnFixedType,
      render: (status: CandidateStatus) => <DatacColorStatusSelect<CandidateStatus> status={status} />
    },
    {
      title: columnNames.applied,
      dataIndex: 'appliedDate',
      key: 'appliedDate',
      width: '16rem',
      sorter: true,
      ellipsis: true,
      render: (applied: Date) => format(applied, 'dd MMM yyyy')
    },
    {
      title: columnNames.center,
      dataIndex: 'centerAbbreviation',
      key: 'centerAbbreviation',
      width: '16rem',
      ellipsis: true,
      sorter: true
    },
    ...variables?.map(variable => ({
      title: <DatacTooltip title={allVariables?.find(v => v.variable === variable)?.title}>{variable}</DatacTooltip>,
      dataIndex: variable,
      key: variable,
      width: '16rem',
      render: (answer: SimpleAnswer) => <CustomCell answer={answer} />
    }))
  ]
}

export const searchAndFilterOptions = (intl: scopedIntlType) => {
  const intlSearchBar = (id: string) => intl(`studies.inclusions.search_bar.${id}`)
  return {
    searchInputPlaceholder: intlSearchBar('search.placeholder'),
    filters: [
      {
        label: intlSearchBar('status.header'),
        name: 'status',
        options: [
          {
            value: CandidateStatus.New,
            label: intl('status.new')
          },
          {
            value: CandidateStatus.FollowUpRequired,
            label: intl('status.follow_up_required')
          },
          {
            value: CandidateStatus.Disqualified,
            label: intl('status.disqualified')
          },
          {
            value: CandidateStatus.Excluded,
            label: intl('status.excluded')
          },
          {
            value: CandidateStatus.Qualified,
            label: intl('status.qualified')
          },
          {
            value: CandidateStatus.Enrolled,
            label: intl('status.enrolled')
          }
        ]
      }
    ]
  }
}
