import './CandidatesTable.less'

import { RouteComponentProps } from '@reach/router'
import React, { useContext, useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import {
  AclAction,
  AclFeature,
  Candidate,
  CandidatesSorter,
  CenterData,
  RecruitmentVariable,
  SelectionKeys,
  SorterOrder,
  SourcedRecruitmentVariable,
  TableColumnVariableSource,
  TableVariable,
  fetchCandidates,
  fetchRecruitmentVariables,
  fetchSubjectRepositoryVariables,
  updateRecruitmentStudy
} from '../../../../requests'
import { TableRecord, createTableRecords } from '../../../../utils'
import { UserContext } from '../../../auth'
import {
  DatacBulkActionsBar,
  DatacMessage,
  DatacTable,
  DatacTableColumnPicker,
  DatacTableSearchAndFilters
} from '../../../common'
import { RecruitmentProfileQuickView } from '../RecruitmentProfile/RecruitmentProfileQuickView'
import { RecruitmentStudyDetailsLayout, RecruitmentStudyDetailsTab } from '../RecruitmentStudyDetailsLayout'
import { useRecruitmentStudyDetailsStore } from '../RecruitmentStudyDetailsStore'
import { CandidatesExport } from './CandidatesExport'
import { getListColumns, searchAndFilterOptions } from './CandidatesTableConfig'
import { CandidatesTableConfirmation } from './CandidatesTableConfirmation'
import { CandidatesTableNumbers } from './CandidatesTableNumbers'

const pageSize = 25

interface Props extends RouteComponentProps {
  isLoadingStudy: boolean
}
export const CandidatesTable: React.FC<Props> = ({ isLoadingStudy }) => {
  const {
    study,
    reloadCandidatesTable,
    candidatesTableOptions,
    setCandidatesTableOptions,
    candidates,
    setCandidates,
    setCandidateToView,
    centers,
    filters,
    setFilters,
    triggerReloadKpis
  } = useRecruitmentStudyDetailsStore()
  const intlCandidates = useScopedIntl('recruitment.study.candidates')
  const intl = useScopedIntl('')
  const [currentPage, setCurrentPage] = useState(1)
  const [countAll, setCountAll] = useState(0)
  const [sorter, setSorter] = useState(candidatesTableOptions.sorter)
  const [search, setSearch] = useState(candidatesTableOptions.search)
  const [isFetchingApplications, setIsFetchingApplications] = useState(false)
  const [isFetchingRecruitmentVariables, setIsFetchingRecruitmentVariables] = useState(false)
  const [isFetchingSubjectVariables, setIsFetchingSubjectVariables] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [selectedCandidates, setSelectedCandidates] = useState<SelectionKeys>([])
  const [isStatusModalOpened, setIsStatusModalOpened] = useState(false)
  const [isExportModalOpened, setIsExportModalOpened] = useState(false)
  const [allRecruitmentVariables, setAllRecruitmentVariables] = useState<RecruitmentVariable[]>([])
  const [allSubjectVariables, setAllSubjectVariables] = useState<TableVariable[]>([])
  const [isEverythingSelected, setIsEverythingSelected] = useState(false)
  const { setStudy } = useRecruitmentStudyDetailsStore()
  const { user } = useContext(UserContext)

  useEffect(() => {
    if (isLoadingStudy) return

    refreshList()
  }, [reloadCandidatesTable])

  useEffect(() => {
    if (isLoadingStudy) return

    fetchCandidatesPage(0, sorter, search, filters)
  }, [isLoadingStudy, centers, search, filters])

  useEffect(() => {
    setIsEverythingSelected(false)
  }, [centers, search, filters, sorter])

  useEffect(() => {
    if (!study || !centers?.length) return

    updateAllVariablesList()
  }, [study, centers, reloadCandidatesTable])

  useEffect(() => {
    if (!candidates?.length || !isEverythingSelected) return

    setSelectedCandidates(candidates.map((candidate: TableRecord<Candidate>) => candidate.key))
    setIsEverythingSelected(true)
  }, [candidates])

  const updateAllVariablesList = () => {
    setIsFetchingRecruitmentVariables(true)
    fetchRecruitmentVariables(
      { studyId: study.id },
      {
        onSuccess: variables => {
          setAllRecruitmentVariables(variables)
          setIsFetchingRecruitmentVariables(false)
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsFetchingRecruitmentVariables(false)
        }
      }
    )
    setIsFetchingSubjectVariables(true)
    fetchSubjectRepositoryVariables({
      onSuccess: variables => {
        setAllSubjectVariables(variables)
        setIsFetchingSubjectVariables(false)
      },
      onRequestError: code => {
        DatacMessage.genericError(intl, code)
        setIsFetchingSubjectVariables(false)
      }
    })
  }

  const refreshList = () => {
    fetchCandidatesPage(currentPage - 1, sorter, search, filters)
    triggerReloadKpis()
  }

  const fetchCandidatesPage = (
    page: number,
    sorter: CandidatesSorter,
    search: string,
    filters: Record<string, string[]>
  ) => {
    setSelectedCandidates([])
    setIsFetchingApplications(true)
    fetchCandidates(
      {
        studyId: study.id,
        options: {
          limit: pageSize,
          offset: page * pageSize,
          sorter,
          search,
          filters: {
            status: filters?.status
          }
        }
      },
      {
        onSuccess: ({ candidates, countAll }) => {
          setCandidates(
            createTableRecords<Candidate>(
              candidates.map(candidate => ({
                ...candidate,
                centerAbbreviation: centers.find((c: CenterData) => c.id === candidate.centerId)?.abbreviation,
                ...candidate.variableAnswers[TableColumnVariableSource.RecruitmentSurvey],
                ...candidate.variableAnswers[TableColumnVariableSource.SubjectDatabase]
              }))
            )
          )
          setCountAll(countAll)
          setIsFetchingApplications(false)
          setIsSearching(false)
          setCurrentPage(page + 1)
          setCandidatesTableOptions({ pageNumber: page, search, sorter })
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsFetchingApplications(false)
          setIsSearching(false)
        }
      }
    )
  }

  const onSearchChange = (value: string) => {
    setIsSearching(true)
    setSearch(value)
  }

  const onPageChange = (page: number) => {
    fetchCandidatesPage(page - 1, sorter, search, filters)
  }

  const onSorterChange = (tableSorter: { field: keyof Candidate; order: SorterOrder }) => {
    const sorterChanged =
      Object.keys(tableSorter).length &&
      ((!sorter && tableSorter.order) || tableSorter.field !== sorter?.field || tableSorter.order !== sorter?.order)

    if (sorterChanged) {
      const newSorter = tableSorter.order
        ? {
            field: tableSorter.field,
            order: tableSorter.order === 'ascend' ? SorterOrder.Ascend : SorterOrder.Descend
          }
        : null
      fetchCandidatesPage(currentPage - 1, newSorter, search, filters)
      setSorter(newSorter)
    }
  }

  const columns = getListColumns({
    columnNames: {
      contactDetail: intlCandidates('column_name.contact_detail'),
      stage: intlCandidates('column_name.stage'),
      applied: intlCandidates('column_name.applied'),
      survey: intlCandidates('column_name.survey'),
      center: intlCandidates('column_name.center')
    },
    variables: ((study?.tableVariables || []) as SourcedRecruitmentVariable[]).map(
      tableVariable => tableVariable.variable
    ),
    allVariables: allRecruitmentVariables || []
  })

  const onRowConfig = (candidate: Candidate) => ({
    onClick: (event: React.MouseEvent<HTMLElement>) => {
      const target = event.target as HTMLElement
      const classesToOmit = ['datac-select', 'ant-select-selection-item', 'datac-select__option']
      if (classesToOmit.some(c => target.closest('div span')?.classList.value.includes(c) || target.matches(`.${c}`)))
        return
      setCandidateToView(candidate)
    }
  })

  const selectedCount = isEverythingSelected ? countAll : selectedCandidates.length

  const variablesLoading = isFetchingRecruitmentVariables || isFetchingSubjectVariables

  const tableOptions = (
    <div className="candidates-table__options">
      <DatacTableColumnPicker
        allColumns={{
          [TableColumnVariableSource.SubjectDatabase]: allSubjectVariables,
          [TableColumnVariableSource.RecruitmentSurvey]: allRecruitmentVariables
        }}
        columns={((variablesLoading ? [] : study?.tableVariables || []) as SourcedRecruitmentVariable[]).map(
          variable => {
            const title =
              variable.source === TableColumnVariableSource.RecruitmentSurvey
                ? allRecruitmentVariables.find(tableVariable => tableVariable.variable === variable.variable)?.title ||
                  ''
                : allSubjectVariables.find(tableVariable => tableVariable.variable === variable.variable)?.title || ''
            return {
              source: variable.source,
              label: variable.variable,
              value: variable.variable,
              sublabel: title
            }
          }
        )}
        updateColumns={columns => {
          updateRecruitmentStudy(
            {
              tableVariables: columns.map(tableColumn => ({ variable: tableColumn.value, source: tableColumn.source })),
              studyId: study.id
            },
            {
              onSuccess: study => {
                setStudy(study)
                fetchCandidatesPage(currentPage - 1, sorter, search, filters)
              },
              onRequestError: code => DatacMessage.genericError(intl, code)
            }
          )
        }}
        isLoading={variablesLoading}
        isPopover
      />
    </div>
  )

  const onStatusModalClose = () => {
    setIsStatusModalOpened(false)
    refreshList()
  }

  return (
    <RecruitmentStudyDetailsLayout currentTab={RecruitmentStudyDetailsTab.Applications}>
      {!isLoadingStudy && (
        <>
          <CandidatesTableNumbers />
          <div className="candidates-table">
            <DatacTableSearchAndFilters
              onSearchChange={onSearchChange}
              onFiltersChange={setFilters}
              isSearching={isSearching}
              searchAndFilterOptions={searchAndFilterOptions(intl)}
              initialSearchValue={search}
              initialFilterValues={filters}
              options={tableOptions}
            />
            <DatacTable
              dataSource={candidates}
              loading={isFetchingApplications}
              columns={columns}
              selectedRows={selectedCandidates}
              setSelectedRows={setSelectedCandidates}
              setIsEverythingSelected={setIsEverythingSelected}
              onRow={onRowConfig}
              scroll={{ x: 1050 }}
              pagination={{
                current: currentPage,
                onChange: onPageChange,
                total: countAll,
                disabled: isFetchingApplications,
                pageSize
              }}
              onChange={(_, __, sorter) => onSorterChange(sorter as { field: keyof Candidate; order: SorterOrder })}
            />
          </div>
          <RecruitmentProfileQuickView onClose={refreshList} />
          <CandidatesExport
            onClose={() => setIsExportModalOpened(false)}
            isOpened={isExportModalOpened}
            studyId={study?.id}
            search={search}
            filters={filters}
            records={selectedCandidates}
            isEverythingSelected={isEverythingSelected}
          />
          <CandidatesTableConfirmation
            onClose={onStatusModalClose}
            isOpened={isStatusModalOpened}
            search={search}
            filters={filters}
            records={candidates.filter((c: Candidate) => selectedCandidates.includes(c.id))}
            isEverythingSelected={isEverythingSelected}
          />
          <DatacBulkActionsBar
            selectedCount={selectedCount}
            onClose={() => setSelectedCandidates([])}
            actions={[
              {
                label: intl('common.export'),
                icon: 'download',
                onClick: () => setIsExportModalOpened(true),
                hidden: !user.canDo(AclFeature.RecruitmentRecords)(AclAction.Export)
              },
              {
                label: intlCandidates('change_status'),
                icon: 'tag',
                onClick: () => setIsStatusModalOpened(true),
                hidden: !user.canDo(AclFeature.Recruitment)(AclAction.Edit)
              }
            ]}
          />
        </>
      )}
    </RecruitmentStudyDetailsLayout>
  )
}
