import { format } from 'date-fns'
import dayjs, { Dayjs } from 'dayjs'
import React from 'react'

import { useScopedIntl } from '../../../../../../hooks'
import { BookedVisitStatus, RecordStatus, ScheduleBookedVisit } from '../../../../../../requests'
import { localeFromPath } from '../../../../../../utils'
import {
  DatacAvatar,
  DatacDropdownMenu,
  DatacIconName,
  DatacOption,
  DatacRecordStatusTag,
  DatacTableSearchAndFilterOptions,
  DatacTooltip,
  scopedIntlType
} from '../../../../../common'
import { getStatusForColor } from '../VisitList'

export const ActionCell: React.VFC = () => {
  // TODO: implement
  return <DatacDropdownMenu options={[]} onClick={() => null} />
}

export const renderParticipantInformation = (
  participantName: string,
  participantId: string,
  participantPhotoThumbnail: string
) => (
  <div className="schedule-booking-visit-list-table__participant">
    <DatacAvatar photoThumbnail={participantPhotoThumbnail} fullName={participantName} />
    <div className="schedule-booking-visit-list-table__participant__information">
      {participantName}
      <div className="schedule-booking-visit-list-table__description">{participantId}</div>
    </div>
  </div>
)

const renderVisitName = (visitName: string, scheduleName: string) => (
  <>
    {visitName}
    <div className="schedule-booking-visit-list-table__description">{scheduleName}</div>
  </>
)

const renderDate = (date: Dayjs, startTime: string, endTime: string) => {
  const intlCalendar = useScopedIntl('calendar')
  const dayString = date.toDate().toLocaleString(localeFromPath(), { weekday: 'long' })

  const getDate = () => {
    if (date.isSame(dayjs(), 'day')) {
      return intlCalendar('today')
    }

    if (date.isSame(dayjs().add(1, 'day'), 'day')) {
      return (
        <>
          {intlCalendar('tomorrow')}
          <div className="schedule-booking-visit-list-table__date__day">{dayString}</div>
        </>
      )
    }

    return (
      <>
        {format(date.toDate(), 'dd MMM yyyy')}
        <div className="schedule-booking-visit-list-table__date__day">{dayString}</div>
      </>
    )
  }

  return (
    <div className="schedule-booking-visit-list-table">
      <div className="schedule-booking-visit-list-table__date">{getDate()}</div>
      <div className="schedule-booking-visit-list-table__description">
        {startTime} - {endTime}
      </div>
    </div>
  )
}

const renderStatus = (status: BookedVisitStatus, cause: string) => {
  const intlStatus = useScopedIntl('status')

  return (
    <DatacTooltip title={cause} placement="top">
      <div>
        <DatacRecordStatusTag
          status={getStatusForColor(status)}
          label={intlStatus(status.toLowerCase())}
          coloredBackground
        />
      </div>
    </DatacTooltip>
  )
}

interface GetColumnsOptions {
  columnNames: {
    date: string
    visitName: string
    participant: string
    location: string
    payment: string
    status: string
  }
  showPayments: boolean
}

export const getListColumns = ({ columnNames, showPayments }: GetColumnsOptions) => {
  return [
    {
      title: columnNames.date,
      dataIndex: 'date',
      key: 'date',
      sorter: true,
      ellipsis: true,
      render: (date: Dayjs, { startTime, endTime }: Partial<ScheduleBookedVisit>) =>
        renderDate(date, startTime, endTime)
    },
    {
      title: columnNames.visitName,
      dataIndex: 'visitName',
      key: 'visitName',
      ellipsis: true,
      sorter: true,
      render: (visitName: string, { scheduleName }: Partial<ScheduleBookedVisit>) =>
        renderVisitName(visitName, scheduleName)
    },
    {
      title: columnNames.participant,
      dataIndex: 'participantName',
      key: 'participantName',
      ellipsis: true,
      sorter: true,
      render: (participantName: string, { participantId, participantPhotoThumbnail }: Partial<ScheduleBookedVisit>) =>
        renderParticipantInformation(participantName, participantId, participantPhotoThumbnail)
    },
    {
      title: columnNames.location,
      dataIndex: 'centerName',
      key: 'centerName',
      ellipsis: true,
      sorter: true
    },
    showPayments
      ? {
          title: columnNames.payment,
          dataIndex: 'paymentAmount',
          key: 'paymentAmount',
          sorter: true,
          render: (paymentAmount: number, { paymentCurrency, paymentStatus }: Partial<ScheduleBookedVisit>) => (
            <DatacRecordStatusTag
              status={paymentStatus || RecordStatus.NotStarted}
              label={`${paymentAmount} ${paymentCurrency}`}
            />
          )
        }
      : { width: 0 },
    {
      title: columnNames.status,
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      width: '14rem',
      render: (status: BookedVisitStatus, { cause }: Partial<ScheduleBookedVisit>) => renderStatus(status, cause)
    },
    {
      title: '',
      dataIndex: '',
      key: 'actions',
      render: () => <ActionCell />,
      width: '5.8rem'
    }
  ]
}

export const searchAndFilterOptions = (
  locationOptions: DatacOption[],
  visitOptions: DatacOption[],
  searchLocations: (search: string) => void,
  intl: scopedIntlType
) => {
  return {
    searchInputPlaceholder: intl('common.search'),
    filters: [
      {
        label: intl('recruitment.study.schedules.visits.column_name.date'),
        name: 'date',
        main: true,
        datePicker: true
      },
      {
        label: intl('recruitment.study.schedules.visits.column_name.status'),
        name: 'status',
        options: (Object.values(BookedVisitStatus) as Array<BookedVisitStatus>).map(status => ({
          value: status,
          label: intl(`status.${status.toLowerCase()}`)
        })),
        main: true,
        icon: 'checkCircle' as DatacIconName
      },
      {
        label: intl('recruitment.study.schedules.visits.column_name.location'),
        name: 'location',
        options: locationOptions,
        handleSearch: searchLocations,
        fetchOnOpen: true
      },
      {
        label: intl('recruitment.study.schedules.visits.column_name.visit_name'),
        name: 'visit',
        options: visitOptions
      }
    ]
  } as DatacTableSearchAndFilterOptions
}
