import './ConfirmationSuccess.less'

import React from 'react'

import CalendarCompleted from '../../../../../../assets/images/calendar-completed.svg'
import { useScopedIntl } from '../../../../../../hooks'
import { DatacTitle } from '../../../../../common'

export const ConfirmationSuccess: React.FC = () => {
  const intlConfirmation = useScopedIntl('recruitment.study.schedules.table.confirmation')

  return (
    <div className="candidates-table-confirmation-success">
      <DatacTitle size="medium">{intlConfirmation('changes_saved')}</DatacTitle>
      <CalendarCompleted />
    </div>
  )
}
