import './VisitList.less'

import { Calendar } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../hooks'
import {
  BookedVisitStatus,
  BookedVisitStatusCount,
  BookedVisitsSorter,
  PaymentType,
  RecordStatus,
  Schedule,
  ScheduleBookedVisit,
  SorterOrder,
  fetchBookedVisits
} from '../../../../../requests'
import { DatacIcon, DatacMessage, DatacRecordStatusTag } from '../../../../common'
import { useRecruitmentStudyDetailsStore } from '../../RecruitmentStudyDetailsStore'
import { VisitListTable } from './VisitListTable'

export const pageSize = 25

const defaultSorter: BookedVisitsSorter = {
  field: 'date',
  order: SorterOrder.Descend
}

export const getStatusForColor = (status: BookedVisitStatus) =>
  // scheduled status has different color in this context
  status === BookedVisitStatus.Scheduled ? RecordStatus.InProgress : status

export const VisitList: React.FC<{ schedules: Schedule[] }> = ({ schedules }) => {
  const [bookedVisitStatusCount, setBookedVisitStatusCount] = useState<BookedVisitStatusCount>()
  const [bookedVisitsDates, setBookedVisitsDates] = useState<Dayjs[]>([])
  const [bookedVisitsCount, setBookedVisitsCount] = useState(0)
  const { study } = useRecruitmentStudyDetailsStore()
  const intlVisits = useScopedIntl('recruitment.study.schedules.visits')
  const intlStatus = useScopedIntl('status')
  const intl = useScopedIntl('')
  const [currentDate, setCurrentDate] = useState<Dayjs>(dayjs())
  const [bookedVisits, setBookedVisits] = useState<ScheduleBookedVisit[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [countAll, setCountAll] = useState(0)
  const [sorter, setSorter] = useState<BookedVisitsSorter>(defaultSorter)
  const [search, setSearch] = useState('')
  const [filters, setFilters] = useState<Record<string, string[]>>({})
  const [isFetchingVisitListPage, setIsFetchingVisitListPage] = useState(true)
  const [isEverythingSelected, setIsEverythingSelected] = useState(false)

  useEffect(() => {
    if (!study.id) return

    fetchVisitListPage(0, sorter, search, filters)
    setIsEverythingSelected(false)
  }, [study, search, filters])

  const fetchVisitListPage = (
    page: number,
    sorter: BookedVisitsSorter,
    search: string,
    filters: Record<string, string[]>
  ) => {
    setIsFetchingVisitListPage(true)
    fetchBookedVisits(
      {
        studyId: study.id,
        options: {
          limit: pageSize,
          offset: page * pageSize,
          sorter: sorter || defaultSorter,
          search,
          filters
        }
      },
      {
        onSuccess: ({ bookedVisitStatusCount, bookedVisitDates, countAll, bookedVisits }) => {
          setBookedVisitStatusCount(bookedVisitStatusCount)
          setBookedVisitsDates(bookedVisitDates)
          setBookedVisitsCount(Object.values(bookedVisitStatusCount).reduce((acc, count) => acc + count, 0))
          setBookedVisits(bookedVisits)
          setCountAll(countAll)
          setCurrentPage(page + 1)
          setIsFetchingVisitListPage(false)
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsFetchingVisitListPage(false)
        }
      }
    )
  }

  const onPageChange = (page: number) => {
    fetchVisitListPage(page - 1, sorter, search, filters)
  }

  const onSorterChange = (tableSorter: BookedVisitsSorter) => {
    const sorterChanged =
      Object.keys(tableSorter).length &&
      ((!sorter && tableSorter.order) || tableSorter.field !== sorter?.field || tableSorter.order !== sorter?.order)

    if (sorterChanged) {
      const newSorter = tableSorter.order
        ? {
            field: tableSorter.field,
            order: tableSorter.order === 'ascend' ? SorterOrder.Ascend : SorterOrder.Descend
          }
        : null
      fetchVisitListPage(currentPage - 1, newSorter, search, filters)
      setSorter(newSorter)
    }
  }

  const onSearchChange = (newPhrase: string) => {
    setSearch(newPhrase)
  }

  const onFiltersChange = (filters: Record<string, string[]>) => {
    setFilters(filters)
  }

  const getStatuses = (withLabels: boolean) =>
    Object.values(BookedVisitStatus)
      .filter(status => withLabels || bookedVisitStatusCount?.[status])
      .map(status => (
        <div
          key={status}
          style={{ flex: withLabels || !bookedVisitsCount ? '1' : bookedVisitStatusCount?.[status] || 0 }}
        >
          <DatacRecordStatusTag
            status={getStatusForColor(status)}
            label={withLabels ? `${bookedVisitStatusCount?.[status] || 0} ${intlStatus(status.toLowerCase())}` : ' '}
          />
        </div>
      ))

  const calendarDateRender = (currentDate: Dayjs) => {
    const isSelected = bookedVisitsDates.find(date => date.isSame(currentDate, 'day'))
    return (
      <div className="visit-list__calendar__cell">
        {currentDate.date()}
        {isSelected && <div className="visit-list__calendar__cell__indicator" />}
      </div>
    )
  }

  return (
    <div className="visit-list">
      <div className="visit-list__left">
        <div className="visit-list__calendar">
          <div className="visit-list__calendar__header">
            <button type="button" onClick={() => setCurrentDate(c => c.clone().subtract(1, 'month'))}>
              <DatacIcon name="chevronLeft" />
            </button>
            <span>{currentDate.format('MMM YYYY')}</span>
            <button type="button" onClick={() => setCurrentDate(c => c.clone().add(1, 'month'))}>
              <DatacIcon name="chevronRight" />
            </button>
          </div>
          <Calendar value={currentDate} fullCellRender={calendarDateRender} />
        </div>
        <div className="visit-list__left__overall">
          <span className="visit-list__left__overall__count">{bookedVisitsCount} </span>
          {intlVisits(bookedVisitsCount === 1 ? 'overall.one' : 'overall.many')}
        </div>
        <div className="visit-list__left__line">{getStatuses(false)}</div>
        <div className="visit-list__left__statuses">{getStatuses(true)}</div>
      </div>
      <VisitListTable
        visits={bookedVisits}
        schedules={schedules}
        countAll={countAll}
        currentPage={currentPage}
        onSorterChange={onSorterChange}
        onFiltersChange={onFiltersChange}
        onSearchChange={onSearchChange}
        search={search}
        filters={filters}
        onPageChange={onPageChange}
        isFetchingVisits={isFetchingVisitListPage}
        isEverythingSelected={isEverythingSelected}
        setIsEverythingSelected={setIsEverythingSelected}
        reloadTable={() => fetchVisitListPage(currentPage - 1, sorter, search, filters)}
        showPayments={study?.paymentType === PaymentType.Visit}
      />
    </div>
  )
}
