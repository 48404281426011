import { RouteComponentProps } from '@reach/router'
import React from 'react'

import { PaymentsDashboard } from '../../../payments/PaymentsDashboard'
import { RecruitmentStudyDetailsLayout, RecruitmentStudyDetailsTab } from '../RecruitmentStudyDetailsLayout'
import { useRecruitmentStudyDetailsStore } from '../RecruitmentStudyDetailsStore'

interface Props extends RouteComponentProps {
  isLoadingStudy: boolean
}
export const RecruitmentPayments: React.FC<Props> = ({ isLoadingStudy }) => {
  const { study } = useRecruitmentStudyDetailsStore()
  return (
    <RecruitmentStudyDetailsLayout currentTab={RecruitmentStudyDetailsTab.Payment}>
      {!isLoadingStudy && <PaymentsDashboard studyId={study.id} />}
    </RecruitmentStudyDetailsLayout>
  )
}
