import { Router } from '@reach/router'
import React from 'react'

import { BasicLayout } from '../../components/BasicLayout'
import { AuthRoute } from '../../components/auth/AuthRoute'
import { SessionTimeoutModal } from '../../components/auth/SessionTimeoutModal'
import { RecruitmentStudiesContent, RecruitmentStudyDetailsContent } from '../../components/recruitment'
import { AccountType } from '../../requests'

const IndexPage = () => {
  return (
    <AuthRoute>
      <BasicLayout>
        <SessionTimeoutModal accountType={AccountType.User} />
        <Router basepath="/:lang/recruitment">
          <RecruitmentStudiesContent path="/" />
          <RecruitmentStudyDetailsContent path="/:studyId/*" />
        </Router>
      </BasicLayout>
    </AuthRoute>
  )
}

export default IndexPage
export const Head = () => <title>Datacapt - Recruitment</title>
