import { useMatch } from '@reach/router'
import { navigate } from 'gatsby-plugin-react-intl'
import React, { useCallback, useReducer } from 'react'

import { useScopedIntl } from '../../../../hooks'
import {
  Block,
  CreateFromStructureResponseHandlers,
  CreateFromTemplatesResponseHandlers,
  DeleteBlockResponseHandlers,
  Feature,
  FetchSectionResponseHandlers,
  FetchStructureResponseHandlers,
  InsertBlockTemplatesOptions,
  InsertTemplatesResponseHandlers,
  QuestionType,
  SaveBlockResponseHandlers,
  SaveBlockTemplateOptions,
  SaveFullTemplateOptions,
  SaveSectionTemplateOptions,
  SaveSubsectionTemplateOptions,
  SaveTemplateResponseHandlers,
  SearchQuestionsResponseHandlers,
  StaticContentType,
  Structure,
  UpdateSectionOptions,
  UpdateSectionResponseHandlers,
  UpdateStructureResponseHandlers,
  UpdateSubsectionOptions,
  UpdateSubsectionResponseHandlers,
  createRecruitmentFromFullTemplates,
  createRecruitmentFromSectionTemplates,
  createRecruitmentFromSubsectionTemplates,
  deleteBlock as deleteBlockRequest,
  fetchRecruitmentQuestionsDependencies,
  fetchRecruitmentSection,
  fetchRecruitmentStructure,
  insertRecruitmentBlockTemplates,
  insertRecruitmentFullTemplates,
  insertRecruitmentSectionTemplates,
  insertRecruitmentSubsectionTemplates,
  saveBlock as saveBlockRequest,
  saveBlockTemplate as saveBlockTemplateRequest,
  saveFullTemplate as saveFullTemplateRequest,
  saveSectionTemplate as saveSectionTemplateRequest,
  saveSubsectionTemplate as saveSubsectionTemplateRequest,
  searchRecruitmentQuestions,
  updateRecruitmentSection,
  updateRecruitmentStructure,
  updateRecruitmentSubsection
} from '../../../../requests'
import { routes } from '../../../../routes'
import { getConditionalQuestionById } from '../../../../utils'
import {
  Builder,
  BuilderAction,
  BuilderContext,
  BuilderProps,
  BuilderTab,
  builderInitialState,
  builderStateReducer
} from '../../../shared/Builder'

const availableQuestionTabs = new Set([BuilderTab.Edit, BuilderTab.Validation, BuilderTab.Conditions])

const availableQuestionTypes = new Set([
  QuestionType.Text,
  QuestionType.Checkbox,
  QuestionType.Radio,
  QuestionType.Dropdown,
  QuestionType.DateTime,
  QuestionType.Number,
  QuestionType.Rating,
  QuestionType.File,
  QuestionType.Slider
])

interface RecruitmentStudyBuilderWrapperProps {
  studyId: string
  children?: React.ReactNode
}

const RecruitmentStudyBuilderWrapper: React.FC<RecruitmentStudyBuilderWrapperProps> = ({ studyId, children }) => {
  const urlParams = useMatch(`/:lang${routes.recruitmentStudyBuilderSection()}`)
  const [builderState, builderDispatch] = useReducer(builderStateReducer, builderInitialState)
  const intlBuilderLocked = useScopedIntl('recruitment.study.builder.locked')

  // studyId is id of recruitment study, regular study is not created yet
  const requestStudyIds = {
    recruitmentId: studyId,
    studyId: null as string
  }

  const updateQuestionsDependencies = () => {
    fetchRecruitmentQuestionsDependencies(requestStudyIds, {
      onSuccess: ({ conditionalQuestions, dateTimes, invalidBlocks, referenceQuestionsIds }) => {
        builderDispatch({
          type: BuilderAction.SET_QUESTION_DEPENDENCIES,
          conditionalQuestions,
          dateTimeQuestions: dateTimes,
          questionsFromStudy: [],
          invalidBlocks,
          referenceQuestionsIds
        })
      }
    })
  }

  const fetchSection = useCallback(
    ({ sectionId }: { sectionId: string }, responseHandlers?: FetchSectionResponseHandlers) => {
      return fetchRecruitmentSection({ sectionId, ...requestStudyIds }, responseHandlers)
    },
    [studyId]
  )

  const fetchStructure = useCallback(
    (responseHandlers?: FetchStructureResponseHandlers) => {
      return fetchRecruitmentStructure(requestStudyIds, responseHandlers)
    },
    [studyId]
  )

  const updateStructure = useCallback(
    ({ structure }: { structure: Structure }, responseHandlers?: UpdateStructureResponseHandlers) => {
      return updateRecruitmentStructure({ structure, ...requestStudyIds }, responseHandlers)
    },
    [studyId]
  )

  const updateSection = useCallback(
    (options: UpdateSectionOptions, responseHandlers?: UpdateSectionResponseHandlers) => {
      return updateRecruitmentSection({ ...requestStudyIds, ...options }, responseHandlers)
    },
    [studyId]
  )

  const updateSubsection = useCallback(
    (options: UpdateSubsectionOptions, responseHandlers?: UpdateSubsectionResponseHandlers) => {
      return updateRecruitmentSubsection({ ...requestStudyIds, ...options }, responseHandlers)
    },
    [studyId]
  )

  const insertFullTemplates = useCallback(
    ({ templateIds }: { templateIds: string[] }, responseHandlers?: InsertTemplatesResponseHandlers) => {
      return insertRecruitmentFullTemplates({ ...requestStudyIds, templateId: templateIds[0] }, responseHandlers)
    },
    [studyId]
  )

  const insertSectionTemplates = useCallback(
    ({ templateIds }: { templateIds: string[] }, responseHandlers?: InsertTemplatesResponseHandlers) => {
      return insertRecruitmentSectionTemplates({ ...requestStudyIds, templateIds }, responseHandlers)
    },
    [studyId]
  )

  const insertSubsectionTemplates = useCallback(
    (
      { sectionId, templateIds }: { sectionId: string; templateIds: string[] },
      responseHandlers?: InsertTemplatesResponseHandlers
    ) => {
      return insertRecruitmentSubsectionTemplates({ ...requestStudyIds, sectionId, templateIds }, responseHandlers)
    },
    [studyId]
  )

  const insertBlockTemplates = useCallback(
    (
      { subsectionId, templateIds }: InsertBlockTemplatesOptions,
      responseHandlers?: InsertTemplatesResponseHandlers
    ) => {
      return insertRecruitmentBlockTemplates({ ...requestStudyIds, subsectionId, templateIds }, responseHandlers)
    },
    [studyId]
  )

  const createFromStructure = useCallback(
    ({ structure }: { structure: Structure }, responseHandlers?: CreateFromStructureResponseHandlers) => {
      return updateRecruitmentStructure({ ...requestStudyIds, structure }, responseHandlers)
    },
    [studyId]
  )

  const createFromFullTemplates = useCallback(
    ({ templateIds }: { templateIds: string[] }, responseHandlers?: InsertTemplatesResponseHandlers) => {
      return createRecruitmentFromFullTemplates({ ...requestStudyIds, templateId: templateIds[0] }, responseHandlers)
    },
    [studyId]
  )

  const createFromSectionTemplates = useCallback(
    ({ templateIds }: { templateIds: string[] }, responseHandlers?: CreateFromTemplatesResponseHandlers) => {
      return createRecruitmentFromSectionTemplates({ ...requestStudyIds, templateIds }, responseHandlers)
    },
    [studyId]
  )

  const createFromSubsectionTemplates = useCallback(
    ({ templateIds }: { templateIds: string[] }, responseHandlers?: CreateFromTemplatesResponseHandlers) => {
      return createRecruitmentFromSubsectionTemplates({ ...requestStudyIds, templateIds }, responseHandlers)
    },
    [studyId]
  )

  const saveFullTemplate = useCallback(
    (options: SaveFullTemplateOptions, responseHandlers?: SaveTemplateResponseHandlers) => {
      return saveFullTemplateRequest(
        { ...requestStudyIds, moduleType: Feature.Recruitment, ...options },
        responseHandlers
      )
    },
    [studyId]
  )

  const saveSectionTemplate = useCallback(
    (options: SaveSectionTemplateOptions, responseHandlers?: SaveTemplateResponseHandlers) => {
      return saveSectionTemplateRequest(
        { ...requestStudyIds, moduleType: Feature.Recruitment, ...options },
        responseHandlers
      )
    },
    [studyId]
  )

  const saveSubsectionTemplate = useCallback(
    (options: SaveSubsectionTemplateOptions, responseHandlers?: SaveTemplateResponseHandlers) => {
      return saveSubsectionTemplateRequest(
        { ...requestStudyIds, moduleType: Feature.Recruitment, ...options },
        responseHandlers
      )
    },
    [studyId]
  )

  const saveBlockTemplate = useCallback(
    (options: SaveBlockTemplateOptions, responseHandlers?: SaveTemplateResponseHandlers) => {
      return saveBlockTemplateRequest(
        { ...requestStudyIds, moduleType: Feature.Recruitment, ...options },
        responseHandlers
      )
    },
    [studyId]
  )

  const saveBlock = useCallback(
    (
      {
        sectionId,
        subsectionId,
        order,
        block
      }: { sectionId: string; subsectionId: string; order: number; block: Block },
      responseHandlers?: SaveBlockResponseHandlers
    ) => {
      return saveBlockRequest(
        { target: Feature.Recruitment, sectionId, subsectionId, order, block, ...requestStudyIds },
        responseHandlers
      )
    },
    [studyId]
  )

  const deleteBlock = useCallback(
    (
      { sectionId, subsectionId, blockId }: { sectionId: string; subsectionId: string; blockId: string },
      responseHandlers?: DeleteBlockResponseHandlers
    ) => {
      return deleteBlockRequest(
        { target: Feature.Recruitment, sectionId, subsectionId, blockId, ...requestStudyIds },
        responseHandlers
      )
    },
    [studyId]
  )

  const sectionRoute = useCallback(
    (sectionId: string) => routes.recruitmentStudyBuilderSection(studyId, sectionId),
    [studyId]
  )

  const searchQuestions = useCallback(
    ({ search }: { search: string }, responseHandlers?: SearchQuestionsResponseHandlers) => {
      return searchRecruitmentQuestions({ search, ...requestStudyIds }, responseHandlers)
    },
    [studyId]
  )

  const placeholderText = (
    <>
      <p>{intlBuilderLocked('title')}</p>
      <p>{intlBuilderLocked('message')}</p>
    </>
  )

  const goToStructureBuilder = useCallback(() => {
    navigate(routes.recruitmentStudyBuilderStructure(studyId))
  }, [studyId])

  return (
    <BuilderContext.Consumer>
      {() => (
        <BuilderContext.Provider
          value={{
            ...builderState,
            builderDispatch,
            target: Feature.Recruitment,
            updateQuestionsDependencies,
            getConditionalQuestionById: (questionId: string) =>
              getConditionalQuestionById(builderState.conditionalQuestions, questionId),
            sectionId: urlParams?.sectionId,
            fetchSection,
            fetchStructure,
            insertFullTemplates,
            insertSectionTemplates,
            insertSubsectionTemplates,
            insertBlockTemplates,
            updateStructure,
            createFromStructure,
            createFromFullTemplates,
            createFromSectionTemplates,
            createFromSubsectionTemplates,
            updateSection,
            updateSubsection,
            saveSectionTemplate,
            saveSubsectionTemplate,
            saveBlockTemplate,
            saveFullTemplate,
            saveBlock,
            deleteBlock,
            goToStructureBuilder,
            isSupportingTemplates: true,
            availableQuestionTabs,
            availableQuestionTypes,
            availableStaticContentTypes: new Set(Object.values(StaticContentType)),
            sectionRoute,
            searchQuestions,
            placeholderText
          }}
        >
          {children}
        </BuilderContext.Provider>
      )}
    </BuilderContext.Consumer>
  )
}

export const RecruitmentStudyBuilder: React.FC<BuilderProps> = props => (
  <RecruitmentStudyBuilderWrapper studyId={props.studyId}>
    <Builder {...props} />
  </RecruitmentStudyBuilderWrapper>
)
