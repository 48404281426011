import './RecruitmentProfileQuickView.less'

import { Button } from 'antd'
import React, { useContext } from 'react'

import { useScopedIntl } from '../../../../../hooks'
import { AclFeature, Candidate } from '../../../../../requests'
import { TableRecord } from '../../../../../utils'
import { SubjectActivity } from '../../../../SubjectRepository/SubjectRepositoryProfile/SubjectActivity'
import { SubjectProfileCharacteristics } from '../../../../SubjectRepository/SubjectRepositoryProfile/SubjectProfileCharacteristics'
import { SubjectStudiesList } from '../../../../SubjectRepository/SubjectRepositoryProfile/SubjectStudiesList'
import { UserContext } from '../../../../auth'
import { DatacDrawer, DatacIcon } from '../../../../common'
import { useRecruitmentStudyDetailsStore } from '../../RecruitmentStudyDetailsStore'
import { RecruitmentProfileHeader } from '../RecruitmentProfileHeader'
import { RecruitmentProfileNotes } from '../RecruitmentProfileNotes'
import { RecruitmentProfileSurvey } from '../RecruitmentProfileSurvey'

interface RecruitmentProfileQuickViewProps {
  onClose: () => void
}

export const RecruitmentProfileQuickView: React.VFC<RecruitmentProfileQuickViewProps> = ({ onClose }) => {
  const intlProfile = useScopedIntl('recruitment.study.profile')
  const { user } = useContext(UserContext)
  const { candidates, candidateToView, setCandidateToView, study } = useRecruitmentStudyDetailsStore()

  const getRelativeSubjectToPreview = (shift: number) => {
    if (!candidateToView) return null
    const index = candidates.findIndex((s: TableRecord<Candidate>) => s.id === candidateToView.id)
    if (index + shift < 0) return null
    return candidates.slice(index + shift)[0]
  }

  const onQuickViewClose = () => {
    setCandidateToView(null)
    onClose()
  }

  return (
    <DatacDrawer
      isOpened={!!candidateToView}
      onClose={onQuickViewClose}
      destroyOnClose
      bodyClass="recruitment-profile-quick-view"
      title={intlProfile('application_details')}
    >
      {!!candidateToView && (
        <>
          <div className="recruitment-profile-quick-view__body">
            <RecruitmentProfileHeader />
            <RecruitmentProfileNotes />
            {user.isSubjectRepositoryEnabled && user.canAccess(AclFeature.SubjectRepository) && (
              <SubjectProfileCharacteristics subjectId={candidateToView.datacaptId} />
            )}
            <RecruitmentProfileSurvey />
            {user.isSubjectRepositoryEnabled && user.canAccess(AclFeature.SubjectRepository) && (
              <SubjectStudiesList subjectId={candidateToView.datacaptId} isInQuickView />
            )}
            <SubjectActivity subjectId={candidateToView.datacaptId} recruitmentId={study.id} />
          </div>

          <div className="recruitment-profile-quick-view__footer">
            <div className="recruitment-profile-quick-view__footer__previous">
              {!!getRelativeSubjectToPreview(-1)?.id && (
                <Button
                  className="datac-button"
                  size="large"
                  type="default"
                  onClick={() => setCandidateToView(getRelativeSubjectToPreview(-1))}
                >
                  <DatacIcon name="chevronLeft" size="xbig" type="blue" />
                  <span>{intlProfile('previous')}</span>
                </Button>
              )}
            </div>
            <div className="recruitment-profile-quick-view__footer__next">
              {!!getRelativeSubjectToPreview(1)?.id && (
                <Button
                  className="datac-button"
                  size="large"
                  type="default"
                  onClick={() => setCandidateToView(getRelativeSubjectToPreview(1))}
                >
                  <span>{intlProfile('next')}</span>
                  <DatacIcon name="chevronRight" size="xbig" type="blue" />
                </Button>
              )}
            </div>
          </div>
        </>
      )}
    </DatacDrawer>
  )
}
