import { RouteComponentProps } from '@reach/router'
import { Button, Form } from 'antd'
import { debounce } from 'lodash'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../hooks'
import {
  RecruitmentStudy,
  SorterOrder,
  Study,
  StudyStatus,
  fetchStudies,
  updateRecruitmentStudy
} from '../../../../../requests'
import { validateRequired } from '../../../../../validation'
import {
  DatacInformationMessage,
  DatacLoading,
  DatacMessage,
  DatacOption,
  DatacSelect,
  DatacSettingsFormItem
} from '../../../../common'
import { useRecruitmentStudyDetailsStore } from '../../RecruitmentStudyDetailsStore'

const studiesListLimit = 50
const allowedStatuses = [StudyStatus.Draft, StudyStatus.Live, StudyStatus.Suspended]

export const RecruitmentStudySettingsLink: React.FC<RouteComponentProps> = () => {
  const intlFields = useScopedIntl('recruitment.study.fields')
  const [isEditingOn, setIsEditingOn] = useState(false)
  const [form] = Form.useForm()
  const { study, setStudy } = useRecruitmentStudyDetailsStore()
  const intl = useScopedIntl('')
  const [studies, setStudies] = useState<Study[]>([])
  const [isFetchingStudyList, setIsFetchingStudyList] = useState(true)
  const [studiesOptions, setStudiesOptions] = useState<DatacOption[]>([])

  useEffect(() => {
    if (!study) return
    fetchStudiesList(study.edcStudy, !!study.edcStudy)
    resetFields()
  }, [study])

  useEffect(() => {
    setStudiesOptions(studies.map(study => ({ label: study.reference, sublabel: study.name, value: study.id })))
  }, [studies])

  const fetchStudiesList = (searchPhrase?: string, firstFetch?: boolean) => {
    fetchStudies(
      {
        options: {
          sorter: { order: SorterOrder.Descend, field: 'dateAdded' },
          limit: studiesListLimit,
          offset: 0,
          filters: { status: allowedStatuses },
          search: searchPhrase
        }
      },
      {
        onSuccess: newStudies => {
          const selected = studies.filter(s => s.id === study.edcStudy)
          setStudies([...selected, ...newStudies.filter(s => s.id !== selected[0]?.id)])
          if (firstFetch) fetchStudiesList()
          else setIsFetchingStudyList(false)
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsFetchingStudyList(false)
        }
      }
    )
  }

  const onSearch = debounce((newPhrase: string) => {
    fetchStudiesList(newPhrase)
  }, 1000)

  const inputDisabled = !isEditingOn

  const resetFields = () => {
    form.setFieldsValue({ edcStudy: study.edcStudy })
  }

  const onCancel = () => {
    setIsEditingOn(false)
    resetFields()
  }

  const onSave = () => {
    form.submit()
  }

  const onEdit = () => {
    setIsEditingOn(true)
  }

  const onSubmit = (data: RecruitmentStudy) => {
    updateRecruitmentStudy(
      { ...data, studyId: study.id },
      {
        onSuccess: study => {
          setIsEditingOn(false)
          setStudy(study)
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  return (
    <div className="recruitment-study-settings__content__body">
      <div className="recruitment-study-settings__content__form">
        <DatacInformationMessage
          className="recruitment-study-settings__content__description"
          message={intlFields('edc_study.description')}
          type="info"
        />
        <Form form={form} onFinish={data => onSubmit(data as RecruitmentStudy)}>
          <DatacLoading isLoading={isFetchingStudyList}>
            <DatacSettingsFormItem
              name="edcStudy"
              label={intlFields('edc_study.label')}
              validate={validateRequired(intl('common.required'))}
              className="recruitment-study-settings__content__body__edc-link"
            >
              <DatacSelect
                options={studiesOptions}
                disabled={inputDisabled}
                size="large"
                placeholder={intlFields('edc_study.placeholder')}
                onSearch={onSearch}
                showSearch
                loading={isFetchingStudyList}
              />
            </DatacSettingsFormItem>
          </DatacLoading>
        </Form>
      </div>
      <div className="study-settings-general__form-controls">
        {isEditingOn ? (
          <>
            <Button size="large" type="default" onClick={onCancel}>
              {intl('common.cancel')}
            </Button>
            <Button size="large" type="primary" onClick={onSave}>
              {intl('common.save')}
            </Button>
          </>
        ) : (
          <Button size="large" type="primary" onClick={onEdit}>
            {intl('common.edit')}
          </Button>
        )}
      </div>
    </div>
  )
}
