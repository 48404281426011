import './CandidatesTableNumbers.less'

import { Progress } from 'antd'
import React from 'react'

import { useScopedIntl } from '../../../../../hooks'
import { CandidateStatus } from '../../../../../requests'
import { DatacBox, DatacIcon, DatacTitle } from '../../../../common'
import { useRecruitmentStudyDetailsStore } from '../../RecruitmentStudyDetailsStore'

export const CandidatesTableNumbers: React.FC = () => {
  const intlNumbers = useScopedIntl('recruitment.study.numbers')
  const { setFilters, study } = useRecruitmentStudyDetailsStore()
  const percent = Math.floor(study.progress)
  const newCandidateStatus = [CandidateStatus.New, CandidateStatus.FollowUpRequired]
  const rejectedStatus = [CandidateStatus.Excluded, CandidateStatus.Disqualified]

  return (
    <DatacBox className="candidates-table-numbers">
      <div className="candidates-table-numbers__qualified">
        <div className="candidates-table-numbers__row">
          <span className="candidates-table-numbers__long">{intlNumbers('qualified')}</span>
          <span>{intlNumbers('target')}</span>
        </div>
        <div className="candidates-table-numbers__row">
          <DatacTitle className="candidates-table-numbers__green">{study.qualifiedCount}</DatacTitle>
          <div className="candidates-table-numbers__bigger">/</div>
          <div className="candidates-table-numbers__long candidates-table-numbers__bigger">{study.target}</div>
          <DatacTitle>{study.target - Math.min(study.qualifiedCount, study.target)}</DatacTitle>
        </div>
        <Progress status="normal" className="ant-progress-completed" percent={percent} showInfo={false} />
        <div className="candidates-table-numbers__row">
          <span className="candidates-table-numbers__long candidates-table-numbers__green">{percent}%</span>
          <span>{100 - Math.min(percent, 100)}%</span>
        </div>
      </div>
      <DatacBox className="candidates-table-numbers__box" onClick={() => setFilters({ status: newCandidateStatus })}>
        <DatacIcon name="arrowRightCircle" type="yellow" />
        <div>
          <DatacTitle className="candidates-table-numbers__title">{study.candidatesCount}</DatacTitle>
          <div className="candidates-table-numbers__subtitle">{intlNumbers('candidate')}</div>
        </div>
      </DatacBox>
      <DatacBox className="candidates-table-numbers__box" onClick={() => setFilters({ status: rejectedStatus })}>
        <DatacIcon name="xCircle" type="red" />
        <div>
          <DatacTitle className="candidates-table-numbers__title">{study.rejectedCount}</DatacTitle>
          <div className="candidates-table-numbers__subtitle">{intlNumbers('rejected')}</div>
        </div>
      </DatacBox>
    </DatacBox>
  )
}
