import './ConfirmationSelect.less'

import classNames from 'classnames'
import React from 'react'

import ApplicationCompleted from '../../../../assets/images/application-completed.svg'
import ApplicationDisqualified from '../../../../assets/images/application-disqualified.svg'
import ApplicationEnrolled from '../../../../assets/images/application-enrolled.svg'
import ApplicationExcluded from '../../../../assets/images/application-excluded.svg'
import ApplicationNew from '../../../../assets/images/application-new.svg'
import CalendarCancelled from '../../../../assets/images/calendar-cancelled.svg'
import CalendarCompleted from '../../../../assets/images/calendar-completed.svg'
import CalendarNotDone from '../../../../assets/images/calendar-not-done.svg'
import { useScopedIntl } from '../../../../hooks'
import { BookedVisitStatus, CandidateStatus } from '../../../../requests'

type SelectedStatus = BookedVisitStatus | CandidateStatus
interface Props {
  status: SelectedStatus
  onSelect: () => void
  hasDescription?: boolean
  isSelected?: boolean
  isVisitStatus?: boolean
}

export const ConfirmationSelect: React.FC<Props> = ({
  status,
  onSelect,
  hasDescription,
  isVisitStatus,
  isSelected
}) => {
  const intlConfirmation = useScopedIntl('recruitment.study.schedules.table.confirmation')
  const intlStatus = useScopedIntl('status')

  const getImage = () => {
    switch (status) {
      case BookedVisitStatus.Completed:
        return isVisitStatus ? <CalendarCompleted /> : <ApplicationCompleted />
      case BookedVisitStatus.NotDone:
        return <CalendarNotDone />
      case BookedVisitStatus.Cancelled:
        return <CalendarCancelled />
      case CandidateStatus.Enrolled:
        return <ApplicationEnrolled />
      case CandidateStatus.Excluded:
        return <ApplicationExcluded />
      case CandidateStatus.Qualified:
        return <ApplicationCompleted />
      case CandidateStatus.Disqualified:
        return <ApplicationDisqualified />
      case CandidateStatus.New:
        return <ApplicationNew />
      default:
        return null
    }
  }

  return (
    <div
      role="button"
      tabIndex={0}
      className={classNames('recruitment-confirmation-select', { active: isSelected })}
      onClick={onSelect}
    >
      {getImage()}
      <div className="recruitment-confirmation-select__name">{intlStatus(status.toLowerCase())}</div>
      {hasDescription && intlConfirmation(`status_description.${status.toLowerCase()}`)}
    </div>
  )
}
