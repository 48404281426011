import { RouteComponentProps } from '@reach/router'
import { Button, Form, Input, InputNumber, Switch } from 'antd'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../hooks'
import {
  FileInfo,
  FileState,
  ImageUploadType,
  RecruitmentDurationUnit,
  RecruitmentStudy,
  fetchFileUrls,
  updateRecruitmentStudy
} from '../../../../../requests'
import { maxEditorCharactersLength, maxParagraphLength, validateRequired } from '../../../../../validation'
import { DatacFormItem, DatacImageUpload, DatacMessage, DatacSelect, DatacSettingsFormItem } from '../../../../common'
import { EditorInput } from '../../../../shared/Editor'
import { useRecruitmentStudyDetailsStore } from '../../RecruitmentStudyDetailsStore'

export const RecruitmentStudySettingsDetails: React.FC<RouteComponentProps> = () => {
  const intlFields = useScopedIntl('recruitment.study.fields')
  const [isEditingOn, setIsEditingOn] = useState(false)
  const [form] = Form.useForm()
  const { study, setStudy } = useRecruitmentStudyDetailsStore()
  const intl = useScopedIntl('')
  const [isDetailsPageEnabled, setIsDetailsPageEnabled] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [editorInitialValue, setEditorInitialValue] = useState<any>(null)
  const [imageError, setImageError] = useState<string>(null)

  useEffect(() => {
    if (!study) return

    resetFields()
  }, [study])

  const inputDisabled = !isEditingOn

  const resetFields = () => {
    form.setFieldsValue({
      displayName: study.displayName,
      summary: study.summary,
      details: study.details,
      imageConfig: [],
      age: study.age,
      sex: study.sex,
      active: study.active,
      visitNumber: study.visitNumber,
      duration: study.duration,
      durationUnit: study.durationUnit || RecruitmentDurationUnit.Days
    })
    setIsDetailsPageEnabled(study.active)
    setEditorInitialValue(study.details)
    resetImageList()
  }

  const onCancel = () => {
    setIsEditingOn(false)
    resetFields()
  }

  const onSave = () => {
    form.submit()
  }

  const onEdit = () => {
    setIsEditingOn(true)
  }

  const onSubmit = (data: RecruitmentStudy & { imageConfig: FileInfo[] }) => {
    updateRecruitmentStudy(
      { ...data, image: data.imageConfig[0]?.id || null, studyId: study.id },
      {
        onSuccess: study => {
          setIsEditingOn(false)
          setStudy(study)
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const durationUnitOptions = [
    { value: RecruitmentDurationUnit.Days, label: intlFields('duration_unit.days') },
    { value: RecruitmentDurationUnit.Weeks, label: intlFields('duration_unit.weeks') },
    { value: RecruitmentDurationUnit.Months, label: intlFields('duration_unit.months') },
    { value: RecruitmentDurationUnit.Years, label: intlFields('duration_unit.years') }
  ]

  const resetImageList = () => {
    if (!study.image) return

    form.setFieldsValue({ imageConfig: [{ id: study.image, state: FileState.Preloading }] })
    fetchFileUrls(
      {
        fileIds: [study.image]
      },
      {
        onSuccess: fileUrls => {
          form.setFieldsValue({
            imageConfig: [{ id: study.image, state: FileState.Ready, url: fileUrls[study.image] }]
          })
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }
  const onImageListChange = (_: FileInfo[], errors: string[]) => {
    setImageError(errors.length ? errors.join(', ') : undefined)
  }

  return (
    <div className="recruitment-study-settings__content__body">
      <div className="recruitment-study-settings__content__form">
        <Form form={form} onFinish={onSubmit}>
          <DatacSettingsFormItem name="active" label={intlFields('active.label')}>
            <Switch disabled={!isEditingOn} checked={isDetailsPageEnabled} onChange={setIsDetailsPageEnabled} />
          </DatacSettingsFormItem>
          <DatacSettingsFormItem
            name="displayName"
            label={intlFields('display_name.label')}
            validate={validateRequired(intl('common.required'))}
          >
            <Input
              disabled={inputDisabled}
              size="large"
              placeholder={intlFields('display_name.placeholder')}
              maxLength={maxParagraphLength}
            />
          </DatacSettingsFormItem>
          <DatacSettingsFormItem
            name="summary"
            label={intlFields('summary.label')}
            validate={validateRequired(intl('common.required'))}
          >
            <Input.TextArea
              disabled={inputDisabled}
              rows={6}
              placeholder={intlFields('summary.placeholder')}
              maxLength={maxEditorCharactersLength}
            />
          </DatacSettingsFormItem>
          <DatacSettingsFormItem name="age" label={intlFields('age.label')}>
            <Input
              disabled={inputDisabled}
              size="large"
              placeholder={intlFields('age.placeholder')}
              maxLength={maxParagraphLength}
            />
          </DatacSettingsFormItem>
          <DatacSettingsFormItem name="sex" label={intlFields('sex.label')}>
            <Input
              disabled={inputDisabled}
              size="large"
              placeholder={intlFields('sex.placeholder')}
              maxLength={maxParagraphLength}
            />
          </DatacSettingsFormItem>
          <DatacSettingsFormItem name="visitNumber" label={intlFields('visit_number.label')}>
            <InputNumber
              disabled={inputDisabled}
              size="large"
              placeholder={intlFields('visit_number.placeholder')}
              min={1}
              step={1}
            />
          </DatacSettingsFormItem>
          <div className="recruitment-study-settings__content__body__duration">
            <DatacSettingsFormItem name="duration" label={intlFields('duration.label')}>
              <InputNumber
                disabled={inputDisabled}
                size="large"
                placeholder={intlFields('duration.placeholder')}
                min={1}
                step={1}
              />
            </DatacSettingsFormItem>
            <DatacFormItem label=" " name="durationUnit" isListField>
              <DatacSelect options={durationUnitOptions} disabled={inputDisabled} />
            </DatacFormItem>
          </div>
          <DatacSettingsFormItem name="imageConfig" label={intlFields('image.label')} error={imageError ?? undefined}>
            <DatacImageUpload
              onChange={onImageListChange}
              disabled={!isEditingOn}
              uploadType={ImageUploadType.Recruitment}
              studyId={study.id}
            />
          </DatacSettingsFormItem>
          <EditorInput
            fieldName="details"
            label={intlFields('description.label')}
            initialValue={editorInitialValue}
            disabled={inputDisabled}
            required={false}
          />
        </Form>
      </div>
      <div className="recruitment-study-settings__content__form-controls">
        {isEditingOn ? (
          <>
            <Button size="large" type="default" onClick={onCancel}>
              {intl('common.cancel')}
            </Button>
            <Button size="large" type="primary" onClick={onSave}>
              {intl('common.save')}
            </Button>
          </>
        ) : (
          <Button size="large" type="primary" onClick={onEdit}>
            {intl('common.edit')}
          </Button>
        )}
      </div>
    </div>
  )
}
