import './RecruitmentStudySettings.less'

import { RouteComponentProps, Router } from '@reach/router'
import React, { useContext } from 'react'

import { RedirectNoAccessWrapper } from '../../../RedirectNoAccessWrapper'
import { UserContext } from '../../../auth'
import { RecruitmentStudyDetailsLayout, RecruitmentStudyDetailsTab } from '../RecruitmentStudyDetailsLayout'
import { RecruitmentStudySettingsDetails } from './RecruitmentStudySettingsDetails'
import { RecruitmentStudySettingsGeneral } from './RecruitmentStudySettingsGeneral'
import { RecruitmentStudySettingsLayout } from './RecruitmentStudySettingsLayout'
import { RecruitmentStudySettingsLink } from './RecruitmentStudySettingsLink'
import { RecruitmentStudySettingsPayment } from './RecruitmentStudySettingsPayment'
import { RecruitmentStudySettingsQrCodes } from './RecruitmentStudySettingsQrCodes'
import { RecruitmentStudySettingsUsers } from './RecruitmentStudySettingsUsers'
import { RecruitmentStudySettingsWashOut } from './RecruitmentStudySettingsWashOut'

export const RecruitmentStudySettings: React.FC<RouteComponentProps> = () => {
  const { user } = useContext(UserContext)

  return (
    <RecruitmentStudyDetailsLayout currentTab={RecruitmentStudyDetailsTab.Settings}>
      <RecruitmentStudySettingsLayout>
        <Router>
          <RedirectNoAccessWrapper hasNoAccess={!user.isRecruitmentEnabled} path="/">
            <RecruitmentStudySettingsGeneral path="/" />
          </RedirectNoAccessWrapper>
          <RedirectNoAccessWrapper hasNoAccess={!user.isRecruitmentEnabled} path="users">
            <RecruitmentStudySettingsUsers path="*" />
          </RedirectNoAccessWrapper>
          <RedirectNoAccessWrapper hasNoAccess={!user.isRecruitmentEnabled} path="details">
            <RecruitmentStudySettingsDetails path="*" />
          </RedirectNoAccessWrapper>
          <RedirectNoAccessWrapper hasNoAccess={!user.isRecruitmentEnabled} path="payment">
            <RecruitmentStudySettingsPayment path="*" />
          </RedirectNoAccessWrapper>
          <RedirectNoAccessWrapper hasNoAccess={!user.isRecruitmentEnabled} path="link">
            <RecruitmentStudySettingsLink path="*" />
          </RedirectNoAccessWrapper>
          <RedirectNoAccessWrapper hasNoAccess={!user.isRecruitmentEnabled} path="qr-codes">
            <RecruitmentStudySettingsQrCodes path="*" />
          </RedirectNoAccessWrapper>
          <RedirectNoAccessWrapper hasNoAccess={!user.isRecruitmentEnabled} path="washout">
            <RecruitmentStudySettingsWashOut path="*" />
          </RedirectNoAccessWrapper>
        </Router>
      </RecruitmentStudySettingsLayout>
    </RecruitmentStudyDetailsLayout>
  )
}
