import './RecruitmentStudySettingsPayment.less'

import { RouteComponentProps } from '@reach/router'
import { Button, Form, InputNumber, Radio } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../hooks'
import { PaymentType, RecruitmentStudy, updateRecruitmentStudy } from '../../../../../requests'
import { validateRequired } from '../../../../../validation'
import { DatacCurrencySelect, DatacFormItem, DatacMessage, DatacSettingsFormItem } from '../../../../common'
import { useRecruitmentStudyDetailsStore } from '../../RecruitmentStudyDetailsStore'

export const RecruitmentStudySettingsPayment: React.FC<RouteComponentProps> = () => {
  const intlFields = useScopedIntl('recruitment.study.fields')
  const intlSettings = useScopedIntl('recruitment.study.settings')
  const [isEditingOn, setIsEditingOn] = useState(false)
  const [isPaymentVisible, setIsPaymentVisible] = useState(false)
  const [currencySuffix, setCurrencySuffix] = useState('')
  const [form] = Form.useForm()
  const { study, setStudy } = useRecruitmentStudyDetailsStore()
  const intl = useScopedIntl('')

  useEffect(() => {
    if (!study) return

    resetFields()
  }, [study])

  const inputDisabled = !isEditingOn

  const resetFields = () => {
    form.setFieldsValue({
      paymentType: study.paymentType,
      payment: study.paymentType === PaymentType.Study ? study.payment : null,
      currency: study.currency
    })
    setCurrencySuffix(study.currency)
    setIsPaymentVisible(study.paymentType === PaymentType.Study)
  }

  const onTypeChange = (e: RadioChangeEvent) => {
    const paymentType: PaymentType = e.target.value
    setIsPaymentVisible(paymentType === PaymentType.Study)
  }

  const onCurrencyChange = (value: string) => {
    setCurrencySuffix(value)
  }

  const onCancel = () => {
    setIsEditingOn(false)
    resetFields()
  }

  const onSave = () => {
    form.submit()
  }

  const onEdit = () => {
    setIsEditingOn(true)
  }

  const onSubmit = (data: RecruitmentStudy) => {
    updateRecruitmentStudy(
      { ...data, studyId: study.id },
      {
        onSuccess: study => {
          setIsEditingOn(false)
          setStudy(study)
        },
        onPaymentConfigDisabled: () => {
          DatacMessage.error(intlSettings('error.title'), intlSettings('error.payment_config_disabled'))
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  return (
    <div className="recruitment-study-settings__content__body">
      <div className="recruitment-study-settings__content__form">
        <Form form={form} onFinish={data => onSubmit(data as RecruitmentStudy)}>
          <DatacSettingsFormItem
            name="paymentType"
            label={intlFields('payment_type.label')}
            validate={validateRequired(intl('common.required'))}
          >
            <Radio.Group
              onChange={onTypeChange}
              className={classNames('recruitment-study-settings__payment-type', {
                'recruitment-study-settings__payment-type--study': isPaymentVisible,
                'recruitment-study-settings__payment-type--disabled': inputDisabled
              })}
              disabled={inputDisabled}
            >
              <Radio.Button value={PaymentType.Visit}>{intlFields('payment_type.visit')}</Radio.Button>
              <Radio.Button value={PaymentType.Study}>{intlFields('payment_type.study')}</Radio.Button>
            </Radio.Group>
          </DatacSettingsFormItem>
          <DatacSettingsFormItem
            name="currency"
            label={intlFields('currency.label')}
            validate={validateRequired(intl('common.required'))}
          >
            <DatacCurrencySelect
              disabled={inputDisabled}
              size="large"
              placeholder={intlFields('currency.placeholder')}
              showSearch
              onChange={onCurrencyChange}
            />
          </DatacSettingsFormItem>
          {isPaymentVisible && (
            <DatacSettingsFormItem label={intlFields('payment.label')} addonAfter={currencySuffix}>
              <DatacFormItem noStyle name="payment" validate={validateRequired(intl('common.required'))}>
                <InputNumber
                  disabled={inputDisabled}
                  size="large"
                  placeholder={intlFields('payment.placeholder')}
                  min={0}
                  precision={0}
                  onKeyDown={e => {
                    if (e.key === '.') {
                      e.preventDefault()
                    }
                  }}
                />
              </DatacFormItem>
            </DatacSettingsFormItem>
          )}
        </Form>
      </div>
      <div className="study-settings-general__form-controls">
        {isEditingOn ? (
          <>
            <Button size="large" type="default" onClick={onCancel}>
              {intl('common.cancel')}
            </Button>
            <Button size="large" type="primary" onClick={onSave}>
              {intl('common.save')}
            </Button>
          </>
        ) : (
          <Button size="large" type="primary" onClick={onEdit}>
            {intl('common.edit')}
          </Button>
        )}
      </div>
    </div>
  )
}
