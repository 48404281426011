import './RecruitmentStudySettingsWashOut.less'

import { RouteComponentProps } from '@reach/router'
import { Button, Form, InputNumber, RefSelectProps, Switch } from 'antd'
import React, { useEffect, useRef, useState } from 'react'

import { useScopedIntl } from '../../../../../hooks'
import { RecruitmentStudy, WashOutPeriodType, fetchZones, updateRecruitmentStudy } from '../../../../../requests'
import { enumToOptions } from '../../../../../utils'
import {
  DatacFormItem,
  DatacMessage,
  DatacOption,
  DatacSelect,
  DatacSettingsFormItem,
  DatacTitle
} from '../../../../common'
import { useRecruitmentStudyDetailsStore } from '../../RecruitmentStudyDetailsStore'

export const RecruitmentStudySettingsWashOut: React.FC<RouteComponentProps> = () => {
  const intlFields = useScopedIntl('recruitment.study.fields')
  const intlFieldsPeriod = useScopedIntl('recruitment.study.fields.duration_unit')
  const [isEditingOn, setIsEditingOn] = useState(false)
  const [currentEnteredZone, setCurrentEnteredZone] = useState('')
  const zoneRef = useRef<RefSelectProps>()
  const [zoneOptions, setZoneOptions] = useState<DatacOption[]>([])
  const [form] = Form.useForm()
  const { study, setStudy } = useRecruitmentStudyDetailsStore()
  const intl = useScopedIntl('')

  useEffect(() => {
    if (!study) return

    resetFields()
  }, [study])

  useEffect(() => {
    fetchZones({
      onSuccess: zones => setZoneOptions(zones.map(zone => ({ value: zone, label: zone }))),
      onRequestError: () => DatacMessage.genericError(intl)
    })
  }, [])

  const inputDisabled = !isEditingOn

  const resetFields = () => {
    form.setFieldsValue({
      washOutActive: false, // TODO: study.washOutActive || false,
      washOutPeriod: study.washOutPeriod,
      washOutPeriodType: study.washOutPeriodType || WashOutPeriodType.Days,
      washOutZones: study.washOutZones
    })
  }

  const onCancel = () => {
    setIsEditingOn(false)
    resetFields()
  }

  const onSave = () => {
    form.submit()
  }

  const onEdit = () => {
    setIsEditingOn(true)
  }

  const onSubmit = (data: RecruitmentStudy) => {
    updateRecruitmentStudy(
      { ...data, studyId: study.id },
      {
        onSuccess: study => {
          setIsEditingOn(false)
          setStudy(study)
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const onZoneSearch = (searchPhrase: string) => {
    setCurrentEnteredZone(searchPhrase)
  }

  const addNewZone = (zone: string) => {
    const selectedZones = form.getFieldValue('washOutZones') || []
    form.setFieldsValue({ washOutZones: [...selectedZones, zone] })
  }

  const onZoneKeyPressed = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key !== 'Enter' ||
      !currentEnteredZone.trim() ||
      zoneOptions.find(({ value }) => value === currentEnteredZone)
    ) {
      return
    }

    setZoneOptions(currentAvailableZones => [
      ...currentAvailableZones,
      { value: currentEnteredZone, label: currentEnteredZone }
    ])
    addNewZone(currentEnteredZone)
    onZoneSearch('')
    setCurrentEnteredZone('')
    zoneRef.current.blur()
  }

  return (
    <div className="recruitment-study-settings__content__body recruitment-study-settings__content__body--with-header">
      <div className="recruitment-study-settings__content__header">
        <div className="recruitment-study-settings__content__header__content">
          <DatacTitle type="h1">{intlFields('wash_out.title')}</DatacTitle>
          {intlFields('wash_out.description')}
        </div>
        <div className="study-settings-general__form-controls">
          {isEditingOn ? (
            <>
              <Button size="large" type="default" onClick={onCancel}>
                {intl('common.cancel')}
              </Button>
              <Button size="large" type="primary" onClick={onSave}>
                {intl('common.save')}
              </Button>
            </>
          ) : (
            <Button
              size="large"
              type="primary"
              onClick={onEdit}
              // TODO: enable for 4.14
              disabled
            >
              {intl('common.edit')}
            </Button>
          )}
        </div>
      </div>
      <div className="recruitment-study-settings__content__form">
        <Form form={form} onFinish={data => onSubmit(data as RecruitmentStudy)}>
          <DatacSettingsFormItem
            name="washOutActive"
            label={intlFields('wash_out_active.label')}
            valuePropName="checked"
          >
            <Switch disabled={inputDisabled} />
          </DatacSettingsFormItem>
          <DatacSettingsFormItem
            label={intlFields('wash_out_period.label')}
            className="recruitment-study-settings__wash-out-period"
            addonAfter={
              <Form.Item noStyle name="washOutPeriodType">
                <DatacSelect disabled={inputDisabled} options={enumToOptions(WashOutPeriodType, intlFieldsPeriod)} />
              </Form.Item>
            }
          >
            <DatacFormItem
              noStyle
              name="washOutPeriod"
              validate={(value, getFieldValue) => {
                if (getFieldValue(['washOutActive']) && !value) {
                  return Promise.reject(intl('common.required'))
                }
                return Promise.resolve()
              }}
            >
              <InputNumber
                disabled={inputDisabled}
                size="large"
                placeholder={intlFields('wash_out_period.placeholder')}
                min={0}
                precision={0}
                onKeyDown={e => {
                  if (e.key === '.') {
                    e.preventDefault()
                  }
                }}
              />
            </DatacFormItem>
          </DatacSettingsFormItem>
          <DatacSettingsFormItem
            name="washOutZones"
            label={intlFields('wash_out_zones.label')}
            validate={(value: string[], getFieldValue) => {
              if (getFieldValue(['washOutActive']) && !value?.length) {
                return Promise.reject(intl('common.required'))
              }
              return Promise.resolve()
            }}
          >
            <DatacSelect
              disabled={inputDisabled}
              size="large"
              placeholder={intlFields('wash_out_zones.placeholder')}
              showSearch
              options={zoneOptions}
              onInputKeyDown={onZoneKeyPressed}
              onSearch={onZoneSearch}
              defaultActiveFirstOption={false}
              selectRef={zoneRef}
              mode="multiple"
            />
          </DatacSettingsFormItem>
        </Form>
      </div>
    </div>
  )
}
