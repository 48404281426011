import './RecruitmentStudiesContent.less'

import { RouteComponentProps, useLocation } from '@reach/router'
import { Input, Pagination } from 'antd'
import { debounce } from 'lodash'
import queryString from 'query-string'
import React, { useContext, useEffect, useState } from 'react'

import DocumentPlaceholderImage from '../../../assets/images/datac-document-placeholder.svg'
import EmptyPlaceholderImage from '../../../assets/images/datacapt-empty.svg'
import { useScopedIntl } from '../../../hooks'
import {
  AclAction,
  AclFeature,
  CenterData,
  RecruitmentStudy,
  SorterOrder,
  fetchCenters,
  fetchRecruitmentStudies
} from '../../../requests'
import { UserContext } from '../../auth'
import { DatacLoading, DatacMessage, DatacPaginationItem, DatacTitle } from '../../common'
import { NewRecruitmentStudy } from './NewRecruitmentStudy'
import { RecruitmentStudiesNumbers } from './RecruitmentStudiesNumbers'
import { RecruitmentStudyCard } from './RecruitmentStudyCard'

const pageSize = 25

export interface StudiesCount {
  currentCount: number
  allCount: number
  recruitingCount: number
  draftCount: number
  endedCount: number
  archivedCount: number
}

export const RecruitmentStudiesContent: React.FC<RouteComponentProps> = ({ navigate }) => {
  const location = useLocation()
  const queryParams = queryString.parse(location.search, { arrayFormat: 'comma' })
  const { user } = useContext(UserContext)
  const userCan = user.canDo(AclFeature.Recruitment)
  const intl = useScopedIntl('')
  const intlRecruitment = useScopedIntl('recruitment')
  const [isFetchingStudyList, setIsFetchingStudyList] = useState(true)
  const [studies, setStudies] = useState<RecruitmentStudy[]>(null)
  const [isSearching, setIsSearching] = useState(false)
  const [noStudiesAvailable, setNoStudiesAvailable] = useState(false)
  const [studiesCount, setStudiesCount] = useState<StudiesCount>(null)
  const [currentPage, setCurrentPage] = useState(0)
  const [searchPhrase, setSearchPhrase] = useState((queryParams.searchPhrase as string) || '')
  const [centers, setCenters] = useState<CenterData[]>([])

  useEffect(() => {
    if (user.shouldShowNextReleaseInfo()) {
      DatacMessage.nextReleaseInfo(intl, user.getNextRelease(), user.closeNextReleaseNotes, user.language)
    }
  }, [user])

  useEffect(() => {
    updateCenters()
  }, [])

  const updateCenters = () => {
    return fetchCenters(
      { options: { sorter: { field: 'abbreviation', order: SorterOrder.Ascend } } },
      {
        onSuccess: ({ centers }) => setCenters(centers),
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const onPageChange = (page: number) => {
    fetchStudiesList(searchPhrase, page - 1)
  }

  const getSearchAndFiltersQuery = (phrase: string) => {
    return `?${queryString.stringify(
      { searchPhrase: phrase },
      { arrayFormat: 'comma', skipNull: true, skipEmptyString: true }
    )}`
  }

  const fetchStudiesList = (searchPhrase?: string, page = currentPage) => {
    fetchRecruitmentStudies(
      {
        options: {
          limit: pageSize,
          offset: page * pageSize,
          search: searchPhrase
        }
      },
      {
        onSuccess: (newStudies, count) => {
          setStudies(newStudies)
          setIsSearching(false)
          setNoStudiesAvailable(count.allStudiesCount === 0)
          setStudiesCount({
            currentCount: count.studiesCount,
            allCount: count.allStudiesCount,
            recruitingCount: count.recruitingStudiesCount,
            draftCount: count.draftStudiesCount,
            endedCount: count.endedStudiesCount,
            archivedCount: count.archivedStudiesCount
          })
          setCurrentPage(page + 1)
          setIsFetchingStudyList(false)
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsFetchingStudyList(false)
        }
      }
    )
  }

  useEffect(() => fetchStudiesList(searchPhrase), [])

  useEffect(() => {
    if (!location.search && searchPhrase) {
      setSearchPhrase(null)
      fetchStudiesList(null, 0)
    }
  }, [location])

  const onSearch = debounce((newPhrase: string) => {
    setIsSearching(true)
    fetchStudiesList(newPhrase, 0)
    navigate(getSearchAndFiltersQuery(newPhrase), { replace: true })
  }, 1000)

  return (
    <main className="recruitment-studies-content">
      <div className="recruitment-studies-content__header">
        <DatacTitle type="h1">{intlRecruitment('title')}</DatacTitle>
        {studies && !noStudiesAvailable && (
          <div className="recruitment-studies-content__controls">
            <Input.Search
              id="recruitment-studies-search"
              className="recruitment-studies-content__search"
              size="large"
              onChange={e => {
                setSearchPhrase(e.target.value)
                onSearch(e.target.value)
              }}
              placeholder={intlRecruitment('search_placeholder')}
              loading={isSearching}
              value={searchPhrase}
            />
            {userCan(AclAction.Add) && <NewRecruitmentStudy centers={centers} />}
          </div>
        )}
      </div>
      <DatacLoading isLoading={isFetchingStudyList}>
        {!!studies?.length && (
          <>
            <RecruitmentStudiesNumbers {...studiesCount} />
            <div className="recruitment-studies-content__list">
              {studies.map((study, index) => (
                <RecruitmentStudyCard
                  study={study}
                  index={index}
                  key={index}
                  centers={centers.filter(c => study.centerIds.includes(c.id))}
                />
              ))}
            </div>
            {studiesCount?.currentCount > pageSize && (
              <div className="recruitment-studies-content__pagination-wrapper">
                <Pagination
                  current={currentPage}
                  itemRender={DatacPaginationItem}
                  onChange={onPageChange}
                  total={studiesCount?.currentCount}
                  showSizeChanger={false}
                  disabled={isSearching}
                  pageSize={pageSize}
                />
              </div>
            )}
          </>
        )}
        {!!studies && noStudiesAvailable && (
          <div className="recruitment-studies-content__no-studies">
            {userCan(AclAction.Add) ? (
              <>
                <DocumentPlaceholderImage className="recruitment-studies-content__no-studies-image" />
                <NewRecruitmentStudy centers={centers} />
              </>
            ) : (
              <>
                <EmptyPlaceholderImage className="recruitment-studies-content__no-studies-image" />
                {intlRecruitment('no_access_to_any')}
              </>
            )}
          </div>
        )}
        {studies && !studies.length && !noStudiesAvailable && (
          <div className="recruitment-studies-content__no-studies">
            <EmptyPlaceholderImage className="recruitment-studies-content__no-studies-image" />
            {intlRecruitment('no_results_for_filters')}
          </div>
        )}
      </DatacLoading>
    </main>
  )
}
