import './ConfirmationPayments.less'

import { Input, InputNumber } from 'antd'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../../../../hooks'
import { BookedVisitStatus, CandidateStatus, PaymentStatus, PaymentType } from '../../../../../../../../requests'
import { maxCommentLength, validateRequired } from '../../../../../../../../validation'
import {
  DatacFormItem,
  DatacIcon,
  DatacInformationMessage,
  DatacRecordStatusTag,
  DatacTitle
} from '../../../../../../../common'

enum ConfirmPaymentType {
  afterStudyEnd,
  cancelled,
  default
}

interface Props {
  theoreticalAmount: number
  applicationStatus: CandidateStatus
  visitStatus: BookedVisitStatus
  paymentType: PaymentType
  setPaymentAmount: (amount?: number) => void
  paymentAmount: number
  currency: string
  paymentComment: string
  setPaymentComment: (comment: string) => void
  isAddingComment: boolean
  setIsAddingComment: (isAddingComment: boolean) => void
}

export const ConfirmationPayments: React.FC<Props> = ({
  setPaymentAmount,
  paymentAmount,
  theoreticalAmount,
  paymentType,
  applicationStatus,
  visitStatus,
  currency,
  paymentComment,
  setPaymentComment,
  isAddingComment,
  setIsAddingComment
}) => {
  const intlConfirmation = useScopedIntl('recruitment.study.schedules.table.confirmation')
  const intl = useScopedIntl('')
  const [confirmationPaymentType, setConfirmationPaymentType] = useState(ConfirmPaymentType.default)
  const [editCancelledPayment, setEditCancelledPayment] = useState(false)
  const [isCommentTriggered, setIsCommentTriggered] = useState(isAddingComment)
  const [isCommentRequired, setIsCommentRequired] = useState(false)

  useEffect(() => {
    setIsCommentRequired(
      confirmationPaymentType === ConfirmPaymentType.cancelled
        ? paymentAmount !== 0
        : theoreticalAmount !== paymentAmount
    )
  }, [confirmationPaymentType, theoreticalAmount, paymentAmount])

  useEffect(() => {
    if (
      confirmationPaymentType === ConfirmPaymentType.afterStudyEnd &&
      applicationStatus === CandidateStatus.Enrolled
    ) {
      setIsAddingComment(false)
      return
    }

    setIsAddingComment(isCommentRequired || isCommentTriggered)
  }, [confirmationPaymentType, applicationStatus, isCommentRequired, isCommentTriggered])

  useEffect(() => {
    setIsCommentTriggered(false)
    setEditCancelledPayment(false)

    if (paymentType === PaymentType.Study && applicationStatus === CandidateStatus.Enrolled) {
      setConfirmationPaymentType(ConfirmPaymentType.afterStudyEnd)
      setPaymentAmount(null)
      return
    }

    if (
      (paymentType === PaymentType.Study && applicationStatus === CandidateStatus.Completed) ||
      (visitStatus === BookedVisitStatus.Completed && applicationStatus !== CandidateStatus.Excluded)
    ) {
      setConfirmationPaymentType(ConfirmPaymentType.default)
      setPaymentAmount(theoreticalAmount)
      return
    }

    setConfirmationPaymentType(ConfirmPaymentType.cancelled)
    setPaymentAmount(0)
  }, [paymentType, applicationStatus, visitStatus])

  return (
    <div className="visit-list-table-confirmation-payments">
      <DatacTitle size="medium">{intlConfirmation('payments')}</DatacTitle>
      {confirmationPaymentType === ConfirmPaymentType.afterStudyEnd && (
        <DatacInformationMessage type="info" message={intlConfirmation('payment_after_study_end')} />
      )}
      {confirmationPaymentType === ConfirmPaymentType.cancelled && (
        <div className="visit-list-table-confirmation-payments__amount">
          {editCancelledPayment ? (
            <>
              <InputNumber
                min={0}
                step={0.01}
                value={paymentAmount}
                onChange={amount => setPaymentAmount(Number(amount))}
              />
              <Input disabled defaultValue={currency} />
              <span>
                {intlConfirmation('planned_payments')}: {theoreticalAmount} {currency}
              </span>
              <DatacRecordStatusTag status={PaymentStatus.Approved} coloredBackground noDot />
            </>
          ) : (
            <div className="visit-list-table-confirmation-payments__amount__cancelled">
              <span>
                {theoreticalAmount} {currency}
              </span>
              <DatacRecordStatusTag status={PaymentStatus.Cancelled} coloredBackground noDot />
              <DatacIcon name="edit" onClick={() => setEditCancelledPayment(true)} />
            </div>
          )}
        </div>
      )}
      {confirmationPaymentType === ConfirmPaymentType.default && (
        <div className="visit-list-table-confirmation-payments__amount">
          <InputNumber
            min={0}
            step={0.01}
            value={paymentAmount != null ? paymentAmount : theoreticalAmount}
            onChange={amount => setPaymentAmount(Number(amount))}
          />
          <Input disabled defaultValue={currency} />
          <span>
            {intlConfirmation('planned_payments')}: {theoreticalAmount} {currency}
          </span>
          <DatacRecordStatusTag status={PaymentStatus.Approved} coloredBackground />
        </div>
      )}
      {isAddingComment ? (
        <div className="visit-list-table-confirmation-payments__comment">
          <DatacFormItem
            name="comment"
            label={intlConfirmation('payment_comment')}
            validate={validateRequired(intl('common.required'))}
          >
            <Input
              type="large"
              maxLength={maxCommentLength}
              value={paymentComment}
              onChange={e => setPaymentComment(e.currentTarget.value)}
            />
          </DatacFormItem>
          {!isCommentRequired && <DatacIcon name="x" type="red" onClick={() => setIsCommentTriggered(false)} />}
        </div>
      ) : (
        confirmationPaymentType !== ConfirmPaymentType.afterStudyEnd && (
          <div
            className="visit-list-table-confirmation-payments__add-comment"
            onClick={() => setIsCommentTriggered(true)}
            role="button"
            tabIndex={0}
          >
            <DatacIcon name="plus" type="transparent" />
            {intlConfirmation('add_payment_comment')}
          </div>
        )
      )}
    </div>
  )
}
