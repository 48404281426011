import './ApplicationStatus.less'

import React from 'react'

import { useScopedIntl } from '../../../../../../hooks'
import { CandidateStatus } from '../../../../../../requests'
import { DatacInformationMessage, DatacTitle } from '../../../../../common'
import { ConfirmationSelect } from '../../../ConfirmationSelect'

interface Props {
  selectedStatus: CandidateStatus
  onStatusChange: (status: CandidateStatus) => void
}

export const ApplicationStatus: React.FC<Props> = ({ selectedStatus, onStatusChange }) => {
  const intlConfirmation = useScopedIntl('recruitment.study.schedules.table.confirmation')

  return (
    <div className="candidates-table-confirmation-application-status">
      <DatacTitle size="medium">{intlConfirmation('select_application_status')}</DatacTitle>
      <div className="candidates-table-confirmation-application-status__select">
        {[CandidateStatus.New, CandidateStatus.Disqualified, CandidateStatus.Qualified].map(status => (
          <ConfirmationSelect
            status={status}
            onSelect={() => onStatusChange(status)}
            isSelected={selectedStatus === status}
            hasDescription
            key={status}
          />
        ))}
      </div>
      <div className="candidates-table-confirmation-application-status__select">
        {[CandidateStatus.Enrolled, CandidateStatus.Excluded, CandidateStatus.Completed].map(status => (
          <ConfirmationSelect
            status={status}
            onSelect={() => onStatusChange(status)}
            isSelected={selectedStatus === status}
            hasDescription
            key={status}
          />
        ))}
      </div>
      {selectedStatus === CandidateStatus.Excluded && (
        <DatacInformationMessage
          type="error"
          title={intlConfirmation('rejected_warning.title')}
          message={intlConfirmation('rejected_warning.message')}
        />
      )}
    </div>
  )
}
